import "core-js/modules/es.array.push.js";
import { getExamPage, cutCardPaper, uploadCutCardPaper, checkPngImg, rotateImg, exam_close, knowCardPaper, kgCheckAuto, zgCheckAuto, pianyiCheckImg, examStep, examStepProcess } from "@/api/api";
export default {
  name: "examList",
  data() {
    return {
      tableData: [],
      dataInfo: [],
      Info: null,
      dialogFormVisible: false,
      pageInfo: {
        pageSize: 10,
        name: '',
        current: 1,
        total: 0
      },
      exportIds: '',
      processList: [],
      real_num: 0,
      getProcessTime: null,
      wx: null //websocket实例
    };
  },
  created() {},
  mounted() {
    this.getList();
  },
  methods: {
    reset() {
      this.pageInfo.name = '';
      this.pageInfo.user_no = '';
    },
    search() {
      this.pageInfo.pageSize = 10;
      this.pageInfo.current = 1;
      this.getList();
    },
    getList() {
      getExamPage(this.pageInfo).then(res => {
        this.pageInfo.total = parseInt(res.data.total);
        this.tableData = res.data.records;
      });
    },
    cutPaper(id) {
      cutCardPaper({
        exam_id: id
      }).then(res => {
        this.$root.ElMessageBox.alert(res.msg);
      });
    },
    knowPaper(id) {
      knowCardPaper({
        exam_id: id
      }).then(res => {
        this.$root.ElMessageBox.alert(res.msg);
      });
    },
    kgCheckAuto(id) {
      kgCheckAuto({
        exam_id: id
      }).then(res => {
        this.$root.ElMessageBox.alert(res.msg);
      });
    },
    zgCheckAuto(id) {
      zgCheckAuto({
        exam_id: id
      }).then(res => {
        this.$root.ElMessageBox.alert(res.msg);
      });
    },
    exam_close(id) {
      exam_close({
        exam_id: id
      }).then(res => {
        this.$root.msgResut(res, this, 'getList');
      });
    },
    checkPngImg(id) {
      checkPngImg({
        exam_id: id
      }).then(res => {
        this.$root.msgResut(res);
      });
    },
    rotateImg(id) {
      rotateImg({
        exam_id: id
      }).then(res => {
        this.$root.msgResut(res);
      });
    },
    pianyiImg(id) {
      pianyiCheckImg({
        exam_id: id
      }).then(res => {
        this.$root.msgResut(res);
      });
    },
    uploadCut(id, type) {
      uploadCutCardPaper({
        exam_id: id,
        type: type
      }).then(res => {
        this.$root.ElMessageBox.alert(res.msg);
      });
    },
    getKnowList(id) {
      window.localStorage.setItem('menuUrl', '/examCard/knowResultList?exam_id=' + id);
      this.$root.useRouter.push({
        path: "/examCard/knowResultList",
        query: {
          exam_id: id
        }
      });
    },
    getPianyiList(id) {
      window.localStorage.setItem('menuUrl', '/examCard/pianyResultInfo?exam_id=' + id);
      this.$root.useRouter.push({
        path: "/examCard/pianyiResultInfo",
        query: {
          exam_id: id
        }
      });
    },
    showRenew(id) {
      this.dialogFormVisible1 = true;
      this.$refs.renewDiv.getInfo(id); //调用子组件方法
    },
    handleSizeChange(e) {
      //修改每页显示条数
      this.pageInfo.pageSize = e;
      this.pageInfo.current = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.pageInfo.current = e;
      this.getList();
    },
    examStep(data) {
      //步骤一操作 偏移矫正 切割 考号识别
      let that = this;
      examStep(data).then(res => {});
      clearInterval(this.getProcessTime);
      this.dialogFormVisible = true;
      this.getProcessTime = setInterval(function () {
        examStepProcess({
          exam_id: data.exam_id
        }).then(res => {
          that.processList = res.data;
          that.real_num = res.data2;
        });
      }, 3000);
      setTimeout(res => {
        that.getList();
      }, 3000);
    },
    getExamStepProcess(id) {
      let that = this;
      clearInterval(this.getProcessTime);
      this.dialogFormVisible = true;
      this.getProcessTime = setInterval(function () {
        examStepProcess({
          exam_id: id
        }).then(res => {
          that.processList = res.data;
          that.real_num = res.data2;
        });
      }, 3000);
    },
    closeDiv() {
      clearInterval(this.getProcessTime);
      this.getList();
      this.dialogFormVisible = false;
    }
  },
  beforeUnmount() {
    clearInterval(this.getProcessTime);
  }
};